/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { Clear, Edit, Save } from "@material-ui/icons";
import { ChangeEvent, MouseEventHandler, useEffect, useState } from "react";
import { isDeviceNameValid } from "../Helper";
import { useStyles } from "../styles";

export type EditableTextProps = {
    value: string;
    onSave: (textToSave: string) => void;
}

const EditableText = (props: EditableTextProps): JSX.Element => {
    const { value, onSave } = props;
    const [dirtyValue, setDirtyValue] = useState<string | undefined>(undefined); //if undefined, we are not editing, otherwise we are and we show this value
    const classes = useStyles();

    useEffect(() => {
        //if the value changes, we exit editing mode and show the value
        setDirtyValue(undefined);
    }, [value]);

    const handleDirtyValueChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        let newDirtyValue = event.target.value;
        if (dirtyValue !== newDirtyValue && isDeviceNameValid(newDirtyValue)) {
            setDirtyValue(newDirtyValue);
        }
    };

    const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (dirtyValue !== undefined) {
            //save clicked
            const newName = dirtyValue.trim();
            if (newName === value) {
                //no change, we simply exit editing mode
                setDirtyValue(undefined);
            } else {
                if (newName === "") {
                    //this is a special case, we want to exit editing mode but it does not happen in case the default name is currently set and we try to delete it.
                    //After mon sys update, the default name is shown again and this input field does not recognize the change.
                    //To fix this, we set the value to undefined to force finish editing mode as the default name is expected to be set again.
                    setDirtyValue(undefined);
                }

                onSave(newName);//let the user component know that we want to save the new name -> it updates the value once it is done and the value change makes us leave text editing mode
            }
        } else {
            //enter editing mode
            setDirtyValue(value);
        }
        event.stopPropagation();//stop the accordion from opening / closing
    };

    return <Grid container className={classes.editableTextContainer}>
        <Grid item xs={10}>
            {dirtyValue !== undefined ?
                <TextField
                    value={dirtyValue}
                    onChange={handleDirtyValueChange}
                    autoFocus
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                style={{ padding: 0 /*makes the icon smaller*/ }}
                                onClick={(event) => {
                                    setDirtyValue(undefined);
                                    event.stopPropagation(); //stops the accordion from opening
                                }}>
                                <Clear />
                            </IconButton>
                        ),
                    }}
                /> : <Typography>{value}</Typography>
            }
        </Grid>
        <Grid item xs={2}>
            <IconButton
                onClick={handleClick}
                size="small"
            >
                {dirtyValue !== undefined ? <Save /> : <Edit />}
            </IconButton>
        </Grid>
    </Grid >
};


export default EditableText;
