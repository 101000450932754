import { Button, Checkbox, CircularProgress, FormControlLabel, Grid, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { postToEndpoint } from '../AxiosHelper';
import { isEmailAddressValid, isPasswordValid } from '../Helper';
import InfoIcon from '../components/InfoIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '15rem'
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

export type LoginPageProps = {
  onLogin: (token: string, navigationStateFromRedirection: string) => void;
}

type PageMode = 'login' | 'signup' | 'pwrequest';

function LoginPage(props: LoginPageProps): JSX.Element {
  const { onLogin } = props;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [pwAgain, setPwAgain] = useState('');
  const [pageMode, setPageMode] = useState('login' as PageMode);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isHideError, setIsHideError] = useState(true);
  const [requestErrorLabel, setRequestErrorLabel] = useState('');
  const [successMessageLabel, setSuccessMessageLabel] = useState('');

  const isEmailValid = isEmailAddressValid(email);
  const isPwValid = isPasswordValid(pw);
  const isPwAgainValid = pw === pwAgain;
  const isLoginValid = isEmailValid && isPwValid;
  const isSignupValid = isLoginValid && isPwAgainValid && isAgreed;
  const isPwRequestValid = isEmailValid;

  const emailErrorLabel = useMemo(() => {
    if (!isHideError && email !== '' && !isEmailValid) {
      return 'user.login.fail.email_invalid';
    }

    return '';
  }, [email, isEmailValid, isHideError]);

  const pwErrorLabel = useMemo(() => {
    if (!isHideError && pw !== '' && !isPwValid) {
      return 'user.login.fail.password_invalid';
    }

    return '';
  }, [isHideError, isPwValid, pw])

  const pwAgainErrorLabel = (isHideError || !isPwValid || isPwAgainValid) ? '' : 'user.signup.fail.password_again_not_match';
  const isAgreedColor = isHideError || isAgreed ? 'primary' : 'error';

  //reset isAgreed, passwordAgain when signup starts. Hide error when switching
  useEffect(() => {
    if (pageMode === 'signup') {
      setPwAgain('');
      setIsAgreed(false);
    }

    setRequestErrorLabel('');
  }, [pageMode])

  const handleGDPRDownloadClick = useCallback(() => {
    // Create a hidden link element
    const link = document.createElement('a');
    link.href = 'https://feedbackpack.eu/wp-content/uploads/2024/04/GDPR_HU_v1.pdf';
    link.download = 'gdpr_hu.pdf';

    // Trigger a click on the link to start the download
    link.click();
  }, []);

  const handleLoginClick = useCallback(() => {
    if (pageMode === 'login') {
      setRequestErrorLabel('');
      setSuccessMessageLabel('');
      if (isLoginValid) {
        setIsBusy(true);
        postToEndpoint('login', { email: email, password: pw })
          .then((response) => {
            onLogin(response?.data.token, location.state?.from || '/');
          })
          .catch((error) => {
            const response = error.response;
            let errorLabel = 'user.login.fail.general_issue';
            if (axios.isAxiosError(error)) {
              if (response?.status === 401) {
                errorLabel = 'user.login.fail.bad_credential';
              } else if (response?.status === 412) {
                errorLabel = 'user.login.fail.unverified';
              }
            }
            setIsHideError(false);
            setRequestErrorLabel(errorLabel);
          })
          .finally(() => setIsBusy(false))
      }
    } else {
      setRequestErrorLabel('');
      setSuccessMessageLabel('');
      setIsHideError(true);
      setPageMode('login');
    }
  }, [email, isLoginValid, location.state?.from, onLogin, pageMode, pw]);

  const handlePwRequestClick = useCallback(() => {
    if (pageMode === 'pwrequest') {
      setRequestErrorLabel('');
      setSuccessMessageLabel('');
      if (isPwRequestValid) {
        setIsBusy(true);
        postToEndpoint('pwrequest', { email: email, locale: i18n.language })
          .then(() => {
            setSuccessMessageLabel('user.pwrequest.done');
          })
          .catch((error) => {
            const response = error.response;
            let errorLabel = 'user.pwrequest.fail.general_issue';
            if (axios.isAxiosError(error)) {
              if (response?.status === 404) {
                errorLabel = 'user.pwrequest.fail.unknown_email';
              }
            }
            setIsHideError(false);
            setRequestErrorLabel(errorLabel);
          })
          .finally(() => setIsBusy(false))
      }
    } else {
      setRequestErrorLabel('');
      setSuccessMessageLabel('');
      setIsHideError(true);
      setPageMode('pwrequest');
    }
  }, [email, i18n.language, isPwRequestValid, pageMode]);

  const handleSignupClick = useCallback(() => {
    if (pageMode === 'signup') {
      setRequestErrorLabel('');
      setSuccessMessageLabel('');
      if (isSignupValid) {
        setIsBusy(true);
        postToEndpoint('signup', { email: email, password: pw, locale: i18n.language })
          .then(() => {
            setSuccessMessageLabel('user.signup.email_sent');
          })
          .catch((error) => {
            const response = error.response;
            let errorLabel = 'user.signup.fail.general_issue';
            if (axios.isAxiosError(error) && response?.status === 409) {
              errorLabel = 'user.signup.fail.already_exists';
            }
            setIsHideError(false);
            setRequestErrorLabel(errorLabel);
          })
          .finally(() => setIsBusy(false))
      }
    } else {
      setRequestErrorLabel('');
      setSuccessMessageLabel('');
      setIsHideError(true);
      setPageMode('signup');
    }
  }, [email, i18n.language, isSignupValid, pageMode, pw]);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      switch (pageMode) {
        case 'login':
          if (e.key === "Enter") {
            handleLoginClick();
          }
          break;
        case 'signup':
          if (e.key === "Enter") {
            handleSignupClick();
          }
          break;
        case 'pwrequest':
          if (e.key === "Enter") {
            handlePwRequestClick();
          }
          break;
      }
    }
  }, [handleLoginClick, handlePwRequestClick, handleSignupClick, pageMode]);

  //By default go to monitor-list if there was no redirection to login earlier.
  //Otherwise go to the page from which we were redirected to login


  const getButton = useCallback((button: PageMode) => {
    const isInfoIconVisible = pageMode === button && ((button === 'login' && !isLoginValid) || (button === 'signup' && !isSignupValid) || (button === 'pwrequest' && !isPwRequestValid));
    const clickHandler = button === 'login' ? handleLoginClick : button === 'signup' ? handleSignupClick : handlePwRequestClick;
    const buttonLabel = 'user.' + button + ((pageMode === 'pwrequest' && button === 'pwrequest') ? '.submit_btn' : '.title');
    return <Grid item style={{ width: '100%' }} key={button}>
      <Grid container alignItems='center'>
        <Grid item xs={isInfoIconVisible ? 10 : 12}>
          <Button
            style={{ clipPath: pageMode === button ? undefined : 'polygon(0 0, 90% 0, 100% 50%, 90% 100%, 0 100%)' }}
            variant="contained"
            color={"primary"}
            onClick={clickHandler}
            disabled={isBusy || isInfoIconVisible}
            fullWidth>
            {isBusy ? <CircularProgress /> : t(buttonLabel)}
          </Button>
        </Grid>
        {isInfoIconVisible && <Grid item xs={2} style={{ textAlign: 'right' }}>
          <InfoIcon infoLabel={t('user.' + button + '.inputs_invalid')} />
        </Grid>}
      </Grid>
    </Grid>
  }, [handleLoginClick, handlePwRequestClick, handleSignupClick, isBusy, isLoginValid, isPwRequestValid, isSignupValid, pageMode, t]);

  const sortedButtons = useMemo(() => {
    const buttons = ['login', 'pwrequest', 'signup'] as PageMode[];
    const sortedButtons = buttons.filter(b => b !== pageMode);
    sortedButtons.unshift(pageMode);
    return sortedButtons.map(b => getButton(b));
  }, [getButton, pageMode]);

  return <form className={classes.root} onChange={() => setIsHideError(false)}>
    <Grid container className={classes.gridContainer}>
      <Grid item>
        <Typography variant="h5" className={classes.title} style={{ width: '100%' }}>
          {t(pageMode === 'signup' ? 'user.signup.title' : pageMode === 'login' ? 'user.login.title' : 'user.pwrequest.title')}
        </Typography>
      </Grid>
      {(successMessageLabel !== '') && <Grid item>
        <Typography variant="body2" style={{ width: '100%', color: "green" }}>
          <Trans i18nKey={successMessageLabel} components={{ 1: <br /> }} />
        </Typography>
      </Grid>}
      <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          label="Email *"
          variant="outlined"
          name='username'
          type="email"
          error={emailErrorLabel !== ''}
          helperText={t(emailErrorLabel)}
          title={email === '' ? t('required_field_title') : ''}
          autoComplete="email"
          value={email}
          onKeyDown={handleKeyDown}
          onChange={(e) => setEmail(e.target.value)} />
      </Grid>
      {pageMode !== 'pwrequest' && <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          label={t('user.login.password') + ' *'}
          name='password'
          type="password"
          variant="outlined"
          error={pwErrorLabel !== ''}
          autoComplete="current-password"
          title={pw === '' ? t('required_field_title') : ''}
          value={pw}
          helperText={t(pwErrorLabel)}
          onKeyDown={handleKeyDown}
          onChange={(e) => setPw(e.target.value)} />
      </Grid>}
      {pageMode === 'signup' && <Grid item style={{ width: '100%' }}>
        <TextField
          fullWidth
          label={t('user.signup.password_again') + ' *'}
          type="password"
          variant="outlined"
          error={pwAgainErrorLabel !== ''}
          value={pwAgain}
          title={pwAgain === '' ? t('required_field_title') : ''}
          helperText={t(pwAgainErrorLabel)}
          onKeyDown={handleKeyDown}
          onChange={(e) => setPwAgain(e.target.value)} />
      </Grid>}
      {pageMode === 'signup' && <Grid item style={{ width: '100%' }}>
        <FormControlLabel control={<Checkbox
          color="primary"
          checked={isAgreed}
          onChange={() => setIsAgreed(!isAgreed)}
        />} label={
          <Typography style={{ fontSize: 'small' }} color={isAgreedColor}>
            {t('user.signup.agree_static')}
            <Link href="#" onClick={handleGDPRDownloadClick} color={isAgreedColor}>
              {t('user.signup.agree_dynamic') + ' *'}.
            </Link>
          </Typography>
        } />
      </Grid>}
      {requestErrorLabel !== '' && <Grid item>
        <Typography variant="body2" style={{ width: '100%' }} color="error">
          {t(requestErrorLabel)}
        </Typography>
      </Grid>}
      {sortedButtons}
    </Grid>
  </form >;
};

export default LoginPage;
