import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type InfoIconProps = {
    infoLabel: string
}

export const InfoIcon = (props: InfoIconProps) => {
    const { infoLabel } = props;
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    // Toggle tooltip visibility
    const handleTooltipToggle = (e: any) => {
        setOpen((prev) => !prev);
        e.stopPropagation();
    };

    // Close tooltip when clicking away
    const handleTooltipClose = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
                title={t(infoLabel)}
                open={open}
            //disableFocusListener // Prevent showing on focus
            //disableHoverListener // Prevent showing on hover
            //disableTouchListener // Prevent showing on touch
            >
                <IconButton size="small" onClick={e => handleTooltipToggle(e)}>
                    <InfoOutlined color="disabled" />
                </IconButton>
            </Tooltip>
        </ClickAwayListener>
    );
}

export default InfoIcon;
