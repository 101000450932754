import { IconButton, Menu, MenuItem, Typography } from "@material-ui/core";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag";

type LanguageInfo = {
    nativeName: string;
    flag: string;
};

//The languages supported by the application. Must match the languages listed in i18n.ts
const languages: Record<string, LanguageInfo> = {
    en: { nativeName: 'English', flag: 'GB' },
    de: { nativeName: 'Deutsch', flag: 'DE' },
    hu: { nativeName: 'Magyar', flag: 'HU' }
}

export type LanguageSelectorProps = {
    language: string;
    onLanguageChange: (newLanguage: string) => void;
    showSelectedNativeName?: boolean;
}

const LanguageSelector = (props: LanguageSelectorProps) => {
    const { language, onLanguageChange, showSelectedNativeName } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (newLanguage: string) => {
        if (newLanguage !== props.language) {
            onLanguageChange(newLanguage);
        }
        handleClose();
    };

    return (
        <div>
            <IconButton
                edge="end"
                color="inherit"
                onClick={handleClick}
            >
                <ReactCountryFlag
                    countryCode={languages[language].flag}
                    svg
                />
                &nbsp;{showSelectedNativeName && <Typography>{languages[language].nativeName}</Typography>}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {Object.keys(languages).map((language) => (
                    <MenuItem key={language} onClick={() => handleLanguageChange(language)}>
                        <ReactCountryFlag countryCode={languages[language].flag} svg />
                        &nbsp; {languages[language].nativeName}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default LanguageSelector;
