import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postToEndpointWithToken } from '../AxiosHelper';
import { isEmailAddressValid, isPasswordValid, isPhoneNumberValid, isUserNameValid } from '../Helper';
import { User } from '../User';
import { AccordionWithTextFieldAndButton } from '../components/AccordionWithTextFieldAndButton';
import InfoIcon from '../components/InfoIcon';
import LanguageSelector from '../components/LanguageSelector';
import { useStyles } from '../styles';

export type SettingsPageProps = {
  token: string,
  user: User;
  hasDevices: boolean;
  onRequestUserUpdate: () => void;
  onLogout: () => void;
}

function SettingsPage(props: SettingsPageProps): JSX.Element {
  const NICKNAME_MAX_LENGTH = 18;
  const { token, user, onRequestUserUpdate, onLogout } = props;
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const [nickName, setNickName] = useState<string>(user.nickName);
  const [email, setEmail] = useState<string>(user.email);
  const [pw, setPw] = useState('');
  const [pwAgain, setPwAgain] = useState('');
  const [pwOld, setPwOld] = useState('');
  const [phone, setPhone] = useState<string>(user.phone ?? '');
  const [isEmailNotif, setIsEmailNotif] = useState<boolean>(user.isEmailNotif);
  const [isSmsNotif, setIsSmsNotif] = useState<boolean>(user.isPhoneNotif);
  const [isChangeOnlyNotif, setIsChangeOnlyNotif] = useState<boolean>(user.isChangeOnlyNotif);
  const [isTopupAccordionExpanded, setIsTopupAccordionExpanded] = useState<boolean>(false);

  const [language, setLanguage] = useState<string>(user.languagePreference);
  const [confirmLabel, setConfirmLabel] = useState('');//empty string means no dialog, 'delete' means delete dialog, 'credentials' means credentials dialog
  const [isBusy, setIsBusy] = useState(false);
  const [requestResultLabel, setRequestResultLabel] = useState('');
  const [isRequestSuccessful, setIsRequestSuccessful] = useState(false);//this indicates if the result is OK/NO of the previous request. The value only matters if requestResultLabel is not ''

  const isEmailValid = isEmailAddressValid(email);
  const isPwValid = pw === '' || isPasswordValid(pw);
  const isPwAgainValid = pw === pwAgain;
  const isPwOldValid = isPasswordValid(pwOld);
  const isPhoneValid = phone === "" || isPhoneNumberValid(phone);
  const isAnythingInvalid = !isEmailValid || !isPwValid || !isPwAgainValid || !isPhoneValid;

  const hasNameChanged = nickName !== user.nickName;
  const hasEmailChanged = email !== user.email;
  const hasPhoneChanged = phone !== (user.phone ?? '');
  const hasPwChanged = pw !== '' || pwAgain !== '';
  const hasLanguageChanged = language !== user.languagePreference;
  const hasPreferenceChanged = isEmailNotif !== user.isEmailNotif || isSmsNotif !== user.isPhoneNotif || isChangeOnlyNotif !== user.isChangeOnlyNotif;
  const hasAnythingChanged = hasNameChanged || hasEmailChanged || hasPhoneChanged || hasPwChanged || hasPreferenceChanged || hasLanguageChanged;
  const hasCredentialsChanged = hasEmailChanged || hasPwChanged;

  const updateUserSettings = useCallback(() => {
    setIsBusy(true);
    let newUser: User = hasPwChanged ? {
      ...user,
      nickName: nickName,
      email: email,
      phone: phone,
      isEmailNotif: isEmailNotif,
      isPhoneNotif: isSmsNotif,
      isChangeOnlyNotif: isChangeOnlyNotif,
      languagePreference: language,
      newPassword: pw
    } : {
      ...user,
      nickName: nickName,
      email: email,
      phone: phone,
      isEmailNotif: isEmailNotif,
      isPhoneNotif: isSmsNotif,
      isChangeOnlyNotif: isChangeOnlyNotif,
      languagePreference: language
    };

    postToEndpointWithToken('updateUser', token, { user: newUser, password: pwOld })
      .then(() => {
        setConfirmLabel('');
        setIsRequestSuccessful(true);
        setRequestResultLabel('user.settings.update_success');
        onRequestUserUpdate();
      })
      .catch((error) => {
        const response = error.response;
        setIsRequestSuccessful(false);
        let errorLabel = 'user.settings.fail.unknown_error';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401) {
            setConfirmLabel('');
            onLogout();
          } else if (response?.status === 403) {
            if (response?.data.includes('password')) {
              errorLabel = 'user.settings.fail.not_allowed';
            } else {
              setConfirmLabel('');
              onLogout();
            }
          }
          else if (response?.status === 409) {
            errorLabel = 'user.signup.fail.already_exists';
          }
        }
        setRequestResultLabel(errorLabel);
      })
      .finally(() => {
        setPwOld('');
        setIsBusy(false);
      })
  }, [email, hasPwChanged, isChangeOnlyNotif, isEmailNotif, isSmsNotif, language, nickName, onLogout, onRequestUserUpdate, phone, pw, pwOld, token, user]);

  const handleSaveBtnClick = useCallback(() => {
    if (!isAnythingInvalid && hasAnythingChanged) {
      if (hasCredentialsChanged) {
        setPwOld('');
        setConfirmLabel('credentials');
      } else {
        updateUserSettings();
      }
    }
  }, [hasAnythingChanged, hasCredentialsChanged, isAnythingInvalid, updateUserSettings]);

  const handleDelete = useCallback(() => {
    setIsBusy(true);
    postToEndpointWithToken('deleteUser', token, { password: pwOld, locale: i18n.language })
      .then(() => {
        setConfirmLabel('');
        onLogout();//auto-logout on delete
      })
      .catch((error) => {
        const response = error.response;
        setIsRequestSuccessful(false);
        let errorLabel = 'user.settings.delete.fail.unknown_error';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401) {
            setConfirmLabel('');
            onLogout();
          } else if (response?.status === 403) {
            if (response?.data.includes('password')) {
              errorLabel = 'user.settings.delete.fail.not_allowed';
            } else {
              setConfirmLabel('');
              onLogout();
            }
          } else if (response?.status === 412) {
            if ((response?.data as string)?.includes('budget')) {
              errorLabel = 'user.settings.delete.fail.negative_budget';
            } else if ((response?.data as string)?.includes('devices')) {
              errorLabel = 'user.settings.delete.fail.has_devices';
            }
          }
        }
        setRequestResultLabel(errorLabel);
      })
      .finally(() => {
        setPwOld('');
        setIsBusy(false);
      })
  }, [i18n.language, onLogout, pwOld, token]);

  const handlePhoneChange = useCallback((phone: string) => {
    let p = phone;
    if (p.length > 0 && p[0] !== '+') {
      p = '+' + p;
    }
    setPhone(p);
  }, []);

  const handleFormKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveBtnClick();
    }
  }, [handleSaveBtnClick]);

  const handleDialogKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (confirmLabel === 'delete') {
        handleDelete();
      } else {
        updateUserSettings();
      }
    }
  }, [confirmLabel, handleDelete, updateUserSettings]);

  const handleTopupClick = useCallback((topupCode: string): Promise<void> => {
    return postToEndpointWithToken('topupSMS', token, { code: topupCode })
      .then(() => {
        onRequestUserUpdate();
      })
      .catch((error) => {
        const response = error.response;
        let errorLabel = 'user.settings.notif.topup.fail.unknown_reason';
        if (axios.isAxiosError(error)) {
          if (response?.status === 401 || response?.status === 403) {
            onLogout();
          } else if (response?.status === 404 || response?.status === 412) {
            errorLabel = 'user.settings.notif.topup.fail.bad_code';
          } else if (response?.status === 409) {
            errorLabel = 'user.settings.notif.topup.fail.used_code';
          }
        }
        return Promise.reject(t(errorLabel));
      })
  }, [onLogout, onRequestUserUpdate, t, token])

  //if anything is done on this page it is an "ACK" to the request result show and we stop showing it
  useEffect(() => {
    setRequestResultLabel('');
  }, [nickName, email, phone, pw, pwAgain, isEmailNotif, isSmsNotif, isChangeOnlyNotif, language, confirmLabel])

  const handleNicknameChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    let temp = event.target.value.substring(0, NICKNAME_MAX_LENGTH);
    if (isUserNameValid(temp)) {
      setNickName(temp)
    }
  }, []);

  //if the phone number becomes invalid, the isSMSnotif is unchecked
  useEffect(() => {
    if (!isPhoneValid) {
      setIsSmsNotif(false);
    }
  }, [isPhoneValid])

  const marginWithinPaper = 15;

  const SMS_BALANCE_WARN_DAYS = 14;
  const smsBalanceDaysLeft = user.smsBalance;
  const smsBalanceColor = !isSmsNotif ? 'rgba(0, 0, 0, 0.87)' : (user.smsBalance <= 0 ? "#d32f2f" : (smsBalanceDaysLeft <= SMS_BALANCE_WARN_DAYS ? "#ff9800" : "rgba(0, 0, 0, 0.87)"));
  const smsNotifCheckboxDisabled = phone === '' || !isPhoneValid;
  const smsFallsBackToEmail = user.smsBalance === 0 && isSmsNotif;
  const smsNotifInfoIconVisible = smsNotifCheckboxDisabled || smsFallsBackToEmail;
  const saveDisabledInfoIconVisible = !hasAnythingChanged || isAnythingInvalid;

  return (
    <FormControl className={classes.mainFrame}>
      <Typography variant="h5" className={classes.pageTitle}>
        {t('user.settings.title')}
      </Typography>
      <Grid container className={classes.mainGridContainer} spacing={1}>
        <Grid item style={{ width: '100%' }}>
          <Paper style={{ borderRadius: 10 }}>
            <Grid container>
              <Grid item style={{ width: '100%', margin: marginWithinPaper }}>
                <TextField
                  label={t('user.settings.user_name') + " *"}
                  variant='outlined'
                  title={nickName.length === 0 ? t('required_field_title') : ''}
                  value={nickName}
                  onKeyDown={handleFormKeyDown}
                  onChange={handleNicknameChange}
                  onBlur={() => setNickName(nickName.trim())}
                  fullWidth />
              </Grid>
              <Grid item style={{ width: '100%', marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <TextField
                  label={t('user.settings.email') + ' *'}
                  type="email"
                  variant='outlined'
                  value={email}
                  helperText={email === '' ? t('user.login.fail.email_empty') : (isEmailValid ? '' : t('user.login.fail.email_invalid'))}
                  error={!isEmailValid}
                  title={email === '' ? t('required_field_title') : ''}
                  onKeyDown={handleFormKeyDown}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth />
              </Grid>
              <Grid item style={{ width: '100%', marginTop: marginWithinPaper, marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <TextField
                  label={t('user.settings.phone')}
                  variant='outlined'
                  value={phone}
                  error={phone !== '' && !isPhoneValid}
                  helperText={phone !== '' && !isPhoneValid ? t('user.settings.fail.phone_invalid') : ''}
                  onKeyDown={handleFormKeyDown}
                  onChange={(e) => { handlePhoneChange(e.target.value) }}
                  fullWidth />
              </Grid>
              <Grid item style={{ width: '100%', marginTop: marginWithinPaper, marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <TextField
                  label={t('user.settings.pw')}
                  type="password"
                  variant='outlined'
                  value={pw}
                  error={pw !== '' && !isPwValid}
                  helperText={pw !== '' && !isPwValid ? t('user.login.fail.password_invalid') : ''}
                  onKeyDown={handleFormKeyDown}
                  onChange={(e) => setPw(e.target.value)}
                  fullWidth />
              </Grid>
              <Grid item style={{ width: '100%', margin: marginWithinPaper }}>
                <TextField
                  label={t('user.settings.pw_again')}
                  type="password"
                  variant='outlined'
                  value={pwAgain}
                  error={!isPwAgainValid}
                  helperText={!isPwAgainValid ? t('user.signup.fail.password_again_not_match') : ''}
                  onKeyDown={handleFormKeyDown}
                  onChange={(e) => setPwAgain(e.target.value)}
                  fullWidth />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper style={{ borderRadius: 10 }}>
            <Grid container>
              <Grid item style={{ width: '100%', marginLeft: marginWithinPaper, marginRight: marginWithinPaper, marginTop: marginWithinPaper }}>
                <Grid container alignItems='center'>
                  <Grid item>
                    <Typography >
                      {t('user.settings.notif.language.title')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LanguageSelector language={language} onLanguageChange={setLanguage} showSelectedNativeName />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: '100%', marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <Grid container alignItems='center'>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox
                        color="primary"
                        checked={!isChangeOnlyNotif}
                        onChange={() => setIsChangeOnlyNotif(prev => !prev)}
                      />}
                      label={t('user.settings.notif.event_based')}
                    />
                  </Grid>
                  <Grid item>
                    <InfoIcon infoLabel={t('user.settings.notif.event_based_title')} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ width: '100%', marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <FormControlLabel control={<Checkbox
                  color="primary"
                  checked={isEmailNotif}
                  onChange={() => setIsEmailNotif(!isEmailNotif)}
                />} label={t('user.settings.notif.is_email')} />
              </Grid>
              <Grid item style={{ width: '100%', marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <Grid container alignItems='center'>
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox
                        color="primary"
                        disabled={smsNotifCheckboxDisabled}
                        checked={isSmsNotif}
                        onChange={() => setIsSmsNotif(!isSmsNotif)}
                      />}
                      label={t('user.settings.notif.is_phone')} />
                  </Grid>
                  {smsNotifInfoIconVisible && <Grid item>
                    <InfoIcon infoLabel={smsNotifCheckboxDisabled ? t('user.settings.phone_not_available') : t('user.settings.notif.topup.no_sms_balance_tooltip')} />
                  </Grid>}
                </Grid>
              </Grid>
              {!smsNotifCheckboxDisabled && <Grid item style={{ width: '100%', margin: marginWithinPaper }}>
                <AccordionWithTextFieldAndButton
                  disabled={false}
                  buttonText={t('user.settings.notif.topup.btn')}
                  buttonTooltip={t('user.settings.notif.topup.btn_tooltip')}
                  expanded={isTopupAccordionExpanded}
                  onExpandedChange={() => setIsTopupAccordionExpanded(prev => !prev)}
                  onClick={handleTopupClick}
                  summaryCounter={user.smsBalance}
                  summaryCounterColor={smsBalanceColor}
                  summaryCounterTooltip={smsNotifCheckboxDisabled || !isSmsNotif ? "" : smsBalanceDaysLeft <= 0 ?
                    t('user.settings.notif.topup.no_sms_balance_tooltip') :
                    smsBalanceDaysLeft <= SMS_BALANCE_WARN_DAYS ?
                      t('user.settings.notif.topup.warn_sms_balance_tooltip', { daysLeft: smsBalanceDaysLeft }) :
                      ""}
                  summaryTextLeft={t('user.settings.notif.topup.budget')}
                  textfieldEmptyText={t('user.settings.notif.topup.input') + ' *'}
                  clickableLink='https://shop.feedbackpack.eu/products/100-sms'
                  clickableLinkText={t('monitoring_system_page.topup.webshop_link')/* TODO adjust link to webshop when webshop supports multiple locales */}
                />
              </Grid>}
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper style={{ borderRadius: 10 }}>
            <Grid container>
              <Grid item>
                <Typography variant="body2" style={{ width: '100%', color: isRequestSuccessful ? 'green' : 'red' }}>
                  {t(requestResultLabel)}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ margin: marginWithinPaper }}>
                <Grid container alignItems='center'>
                  <Grid item xs={saveDisabledInfoIconVisible ? 11 : 12}>
                    <Button
                      disabled={isBusy || saveDisabledInfoIconVisible}
                      variant="contained"
                      color="primary"
                      onClick={handleSaveBtnClick}
                      fullWidth>
                      {isBusy ? <CircularProgress /> : t('user.settings.save_btn')}
                    </Button>
                  </Grid>
                  {saveDisabledInfoIconVisible && <Grid item xs={1}>
                    <InfoIcon infoLabel={!hasAnythingChanged ? t('user.settings.save_disabled_tooltip_no_change') : t('user.settings.save_disabled_tooltip_error')} />
                  </Grid>}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginLeft: marginWithinPaper, marginRight: marginWithinPaper }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => onLogout()}
                  fullWidth>
                  {t('user.settings.logout_btn')}
                </Button>
              </Grid>
              <Grid item xs={12} style={{ margin: marginWithinPaper }}>
                <Button
                  disabled={isBusy}
                  variant="contained"
                  color="secondary"
                  onClick={() => setConfirmLabel('delete')}
                  fullWidth>
                  {isBusy ? <CircularProgress /> : t('user.settings.delete.btn')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={confirmLabel !== ''} >
        <DialogTitle>{t('user.settings.' + confirmLabel + '.confirm_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('user.settings.' + confirmLabel + '.confirm_body')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container alignItems='center' justifyContent='flex-start' spacing={2}>
            <Grid item xs={8} >
              <TextField
                label={t('user.settings.' + confirmLabel + '.pw')}
                type="password"
                variant='outlined'
                value={pwOld}
                error={requestResultLabel !== ''}
                helperText={t(requestResultLabel)}
                onKeyDown={handleDialogKeyDown}
                onChange={(e) => {
                  setRequestResultLabel('');
                  setPwOld(e.target.value)
                }}
                fullWidth />
            </Grid>
            <Grid item xs={2}>
              <Button
                disabled={isBusy || !isPwOldValid}
                onClick={() => { confirmLabel === 'delete' ? handleDelete() : updateUserSettings() }}
                variant="contained"
                color='secondary'>
                {t('user.settings.' + confirmLabel + '.confirm_ack_btn')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setConfirmLabel('')}
                variant="outlined"
                color="primary">
                {t('user.settings.' + confirmLabel + '.confirm_cancel_btn')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
}

export default SettingsPage;
