import { Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useLocation } from "react-router-dom";
import { DeviceWithState } from "../Device";
import { coordinateFormatter, getDeviceStatusColor, getLocalDateStringFromUtcDateString, isDeviceStateRecent } from "../Helper";
import InfoIcon from "../components/InfoIcon";

interface MapPageProps {
  devicesWithState: DeviceWithState[]
}

const MapPage = (props: MapPageProps): JSX.Element => {
  const { devicesWithState } = props;
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const locationFromUrl = new URLSearchParams(location.search).get('location')?.split(',').map(Number);

  const deviceNotIgnored = devicesWithState.filter(d => !d.deviceDescriptor.isIgnored);
  const recentDevices = deviceNotIgnored.filter(d => isDeviceStateRecent(d.deviceState));
  const [showOldDevice, setShowOldDevice] = useState(recentDevices.length === 0);

  const devicesToShow = useMemo(() => {
    return showOldDevice ? deviceNotIgnored : recentDevices;
  }, [deviceNotIgnored, recentDevices, showOldDevice]);

  const zoom = useMemo(() => {
    if (devicesToShow.length === 0) {
      return i18n.language === 'hu' ? 7 : 4; // Default zoom level in case there is no device to show. Size of Hungary if HU or size of Europe if EN
    }

    // Calculate bounding box
    let minLat = devicesToShow[0].deviceState.latitude!;
    let maxLat = minLat;
    let minLng = devicesToShow[0].deviceState.longitude!;
    let maxLng = minLng;

    devicesToShow.forEach(deviceWithState => {
      const [lat, lng] = [deviceWithState.deviceState.latitude!, deviceWithState.deviceState.longitude!];
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    });

    // Calculate optimal zoom level
    const mapWidth = window.innerWidth; // or any other relevant dimension
    const mapHeight = window.innerHeight; // or any other relevant dimension
    const latRange = maxLat - minLat;
    const lngRange = maxLng - minLng;
    const MAX_ZOOM_LEVEL = 18; // Max zoom level of Leaflet. The higher the number, the more we are zoomed in.
    const latZoomLevel = latRange === 0 ? MAX_ZOOM_LEVEL : (Math.log2(360 * (mapHeight / latRange / 256)) - 1); // 256 is the height of a tile. The "-1" makes all the POIs visible
    const lngZoomLevel = lngRange === 0 ? MAX_ZOOM_LEVEL : (Math.log2(180 * (mapWidth / lngRange / 256)) - 1); // 256 is the width of a tile
    return Math.min(latZoomLevel, lngZoomLevel, MAX_ZOOM_LEVEL);
  }, [devicesToShow, i18n.language]);

  const center = useMemo(() => {
    if (locationFromUrl?.length === 2) {
      return locationFromUrl!;
    }

    if (devicesToShow.length === 0) {
      return [46.850384, 19.083496]; // Default center point
    }

    let minLat = devicesToShow[0].deviceState.latitude!;
    let maxLat = minLat;
    let minLng = devicesToShow[0].deviceState.longitude!;
    let maxLng = minLng;

    devicesToShow.forEach(deviceWithState => {
      const [lat, lng] = [deviceWithState.deviceState.latitude!, deviceWithState.deviceState.longitude!];
      minLat = Math.min(minLat, lat);
      maxLat = Math.max(maxLat, lat);
      minLng = Math.min(minLng, lng);
      maxLng = Math.max(maxLng, lng);
    })
    return [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
  }, [devicesToShow, locationFromUrl])

  const markersToShow = useMemo(() => {
    return devicesToShow.map((deviceWithState, index) => {
      const color = getDeviceStatusColor(deviceWithState.deviceState.status);
      // Custom pin icon
      const pinIcon = new L.Icon({
        iconUrl: require('../pic/' + color + '_pin.png'),
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32],
      })

      return (
        <Marker key={index} position={[deviceWithState.deviceState.latitude!, deviceWithState.deviceState.longitude!]} icon={pinIcon}>
          <Popup>
            <Grid container style={{ justifyContent: "center", maxWidth: '280px' }}>
              <Grid item>
                <Typography>{deviceWithState.deviceDescriptor.name}</Typography>
              </Grid>
              <Grid item>
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell variant="body" style={{ width: '53%' }}>{i18n.t('device.status.title')}</TableCell>
                      <TableCell variant="body">{i18n.t('device.status.' + deviceWithState.deviceState.status.toLowerCase())}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{i18n.t('device.location.title')}</TableCell>
                      <TableCell variant="body">
                        {t('device.location.latitude', { latitude: coordinateFormatter(i18n.language).format(deviceWithState.deviceState.latitude!) })}
                        <br />
                        {t('device.location.longitude', { longitude: coordinateFormatter(i18n.language).format(deviceWithState.deviceState.longitude!) })}
                        <br />
                        <a href={`https://www.google.com/maps/dir/?api=1&destination=${deviceWithState.deviceState.latitude},${deviceWithState.deviceState.longitude}&travelmode=driving`} target="_blank" rel="noopener noreferrer">
                          {i18n.t('device.googleMaps')}
                        </a>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{i18n.t('device.battery')}</TableCell>
                      <TableCell variant="body">{deviceWithState.deviceState.battery}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.temperature')}</TableCell>
                      <TableCell variant="body">{deviceWithState.deviceState.temperature}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.signal_strength')}</TableCell>
                      <TableCell variant="body">{deviceWithState.deviceState.signalStrength}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">{t('device.last_seen')}</TableCell>
                      <TableCell variant="body">{getLocalDateStringFromUtcDateString(deviceWithState.deviceState.lastSeen, i18n.language)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Popup>
        </Marker>
      );
    })
  }, [devicesToShow, i18n, t]);

  return <div style={{ height: "80vh" }}>
    <Grid container alignItems="center">
      <Grid item>
        <FormControlLabel
          control={<Checkbox
            checked={!showOldDevice}
            color="primary"
            onChange={() => setShowOldDevice(v => !v)}
          />}
          label={t('map.only_show_recent_text')} />
      </Grid>
      <Grid item>
        <InfoIcon infoLabel="map.only_show_recent_tooltip" />
      </Grid>
    </Grid>
    <MapContainer center={[center[0], center[1]]} zoom={zoom} style={{ height: "100%" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {markersToShow}
    </MapContainer>
  </div>
}

export default MapPage;
